import type { CSSProperties, FC, ReactNode } from 'react';
import * as S from './styles';

interface SectionContainerProps {
  children?: ReactNode;
  style?: CSSProperties;
}

const SectionContainer: FC<SectionContainerProps> = ({ children, style }) => {
  return <S.SectionContainer style={style}>{children}</S.SectionContainer>;
};

export default SectionContainer;
